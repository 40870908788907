function isValidPassword(pwNew, pwRepeat) {
    const p = pwNew
    const pr = pwRepeat

    let errorMessage = ''
    let isValid = true

    if (p.length < 1 || pr.length < 1) {
        errorMessage = "Alle Felder müssen ausgefüllt sein"
        isValid = false
    }
    if (p != pr) {
        errorMessage = "Passwörter stimmen nicht überein"
        isValid = false
    }
    if (!(p.length > 8)) {
        errorMessage = "Passwort ist zu kurz"
        isValid = false
    }
    if (p.search(/[A-Z]/) < 0) {
        errorMessage = "Passwort muss min 1 Großbuchstaben enthalten"
        isValid = false
    }
    if (p.search(/[0-9]/) < 0) {
        errorMessage = "Passwort muss min 1 Zahl enthalten"
        isValid = false
    }
    if (p.search(/[!@#$%^&*?_]/) < 0) {
        errorMessage = "Passwort muss min 1 Sonderzeichen enthalten"
        isValid = false
    }

    return {
        isValid: isValid,
        errorMessage: errorMessage,
    }
}





function isValidEmail(email) {
    // if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    if (/^[a-z0-9!'#$%&*+\/=?^_`{|}~-]+(?:\.[a-z0-9!'#$%&*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-zA-Z]{2,}$/i.test(email)) {
        return true
    }
    return false
}



function isValidPhoneNo(phoneNo) {
    // if (/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/.test(phoneNo)) {
    if (/^\+([0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\/]?[0-9])+$/.test(phoneNo)) {
        return true
    }
    return false
}



function isValidDate(date) {
    //(Schritt 1) Fehlerbehandlung
    if (!date) return false;
    date = date.toString();

    //(Schritt 2) Aufspaltung des Datums
    date = date.split('.');
    if (date.length != 3) return false;

    //(Schritt 3) Entfernung der fuehrenden Nullen und Anpassung des Monats
    date[0] = parseInt(date[0], 10);
    date[1] = parseInt(date[1], 10) - 1;

    //(Schritt 4) Behandlung Jahr nur zweistellig
    if (date[2].length == 2) date[2] = '20' + date[2];

    //(Schritt 5) Erzeugung eines neuen Dateobjektes
    var validationDate = new Date(date[2], date[1], date[0]);

    //(Schritt 6) Vergleich, ob das eingegebene Datum gleich dem JS-Datum ist
    if (
            validationDate.getDate() == date[0] && 
            validationDate.getMonth() == date[1] && 
            validationDate.getFullYear() == date[2] ) {
        return true
        } else {
            return false;
    }
}



export default { isValidPassword, isValidEmail, isValidPhoneNo, isValidDate} ;