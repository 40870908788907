import Vue from 'vue'
function errorNotifier(status) {
    //  Handle 403 Errors
    if (status === 403) {
        Vue.prototype.$vs.notify({
            title: "Fehler",
            text: "Zugriff verweigert, die Daten wurden nicht gespeichert.",
            color: 'danger',
        });
        return
    }

        //  Handle 404 Errors
    if (status === 404) {
        Vue.prototype.$vs.notify({
            title: "Fehler",
            text: "Es ist ein Fehler augetreten, die Datei muss zuerst gespeichert werden, bevor sie gelöscht werden kann.",
            color: 'danger',
        });
        return
    }
    if (status === 409) {
        Vue.prototype.$vs.notify({
            title: "Fehler",
            text: "Diese Person existiert bereits! Verwenden Sie die Funktion 'Existierende Person verwenden?', um die gewünschte Person anzulegen.",
            color: 'danger',
        });
        return
    }

    //  Handle 4XX Errors
    if ((/^4(.*){2}$/.test(status))) {
        Vue.prototype.$vs.notify({
            title: "Fehler",
            text: "Es ist ein Fehler aufgetreten, die Daten wurden nicht gespeichert.",
            color: 'danger',
        });
    }

    //  Handle 5XX Errors
    if (/^5(.*){2}$/.test(status)) {
        Vue.prototype.$vs.notify({
            title: "Fehler",
            text: "Es ist ein Fehler aufgetreten, bitte wenden Sie sich an unseren Support, wenn der Fehler weiterhin auftritt.",
            color: 'danger',
        });
    }
}



export default errorNotifier;