<template>
  <div class="container">
    <div class="center_out h-full">
        <div class="card login-card">    
            <div class="login-card-1" id="login-card-1">

            </div>
            <div class="login-card-2">
                <img src="/images/logo.svg" alt="">
                <div class="card-inner">
                    <p style="text-align: left;">Sie haben ihr Passwort vergessen und möchten ein neues Passwort für folgende Email beantragen:</p>
                    <form action="">
                        <div class="from-group mt-2">
                            <input type="text" class="form-field" placeholder="Email-Adresse" v-model="email">
                        </div>
                        <div class="lower-con">
                            <button type="button" class="from-btn" @click="resetPassword()">Beantragen</button>
                            
                            <div class="mt-4">
                                <router-link class="router-link" :to="{ name: 'Login' }">{{"&lt; zurück zum Login"}}</router-link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <small class="version mt-3">Version: {{version}}</small>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import validators from "@/plugins/inputValidators";
import errorNotifier from "@/plugins/errorNotifier";

export default Vue.extend({
  name: 'ResetPassword',
  data() {
      return {
          email: ''
      }
  },
  computed: {
        version() {
            if (GIT_DESCRIBE !== null) {
		// // console.log(error('App version git hash', GIT_DESCRIBE.hash)
                return GIT_DESCRIBE.tag + " - " + GIT_DESCRIBE.hash
            } else {
                return "no-version"
            }
        }
  },
  methods: {
    async resetPassword() {
        if ((this.email.trim() == '' ) || (!validators.isValidEmail(this.email))) {
            this.$vs.notify({
                color: "danger",
                position: "bottom-right",
                title: 'Eingabe fehlerhaft, bitte überprüfen!',
            })
            return
        }

        let status = await this.$store.dispatch('auth/resetPassword', this.email)
        if (status === 200) {
            this.$vs.notify({
                color: "success",
                position: "bottom-right",
                title: "Passwort wurde zurückgesetzt, sie erhalten ein neues Passwort per Email.",
            });
        } else {
            errorNotifier(status)
        }

        this.$router.push({ name: "Login"})
    }
  }
});
</script>


<style  scoped>

#login-card-1 {
    background: url('../../public/images/login-image.png');
    background-repeat: no-repeat;
    background-size:  cover, auto;
    background-position: center center;
}
.version {
    font-size: 0.75em;
    color: rgb(202, 202, 202);
}

</style>
